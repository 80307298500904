import { Box } from '../components/elements';

const StyleGuide = () => (
  <Box p={3} width="30%">
    <h1>Heading 1</h1>
    <h2>Heading 2</h2>
    <h3>Heading 3</h3>
    <h4>Heading 4</h4>
    <hr />
    <p>Plain text</p>
    <a href="#">Link</a>
    <br />
    <span>Inline text</span>
    <hr />
    <h4>Unordered list</h4>
    <ul>
      <li>List item</li>
      <li>List item</li>
      <li>List item</li>
    </ul>
    <hr />
    <h4>Ordered list</h4>
    <ol>
      <li>List item</li>
      <li>List item</li>
      <li>List item</li>
    </ol>
    <hr />
    <h4>Buttons:</h4>
    <button type="button">Button 1</button>
    <hr />
    <h4>Inputs:</h4>
    <form action="submit">
      <label htmlFor="email">
        <input id="email" type="email" name="email" placeholder="email" />
      </label>
    </form>
  </Box>
);

export default StyleGuide;
